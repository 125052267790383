.container-register {
	padding: 1rem;
}

.container-register .row-heading {
	padding-left: 1rem;
	font-size: 200%;
	font-weight: 600;
	text-transform: uppercase;
	color: #6b6666;
}

.container-register .row-form {
	text-align: center;
}

.container-register .form-group {
	justify-content: center;
	margin-top: 1rem !important;
}

.container-register label {
	text-align: right;
	font-weight: 500;
	color: #6e6969;
}

.container-register input {
	border-radius: 0.5rem !important;
}

.container-register form {
	height: 80% !important;
	margin: auto !important;
}

.container-register #btn-register {
	background-color: #000000 !important;
	font-size: 150% !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	border-radius: 0.4rem !important;
	width: 40%;
}

.container-register #btn-register:hover {
	background-color: white !important;
	color: black !important;
	font-size: 150% !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	border-radius: 0.4rem !important;
	width: 40%;
}

.container-register .row-login {
	margin-top: 1rem !important;
	justify-content: center;
}

.container-register .row-link {
	justify-content: center;
	margin-top: 0.5rem !important;
}

.container-register .link {
	color: black;
	font-weight: 500;
}

.container-register .row-feedback {
	justify-content: center;
	color: red;
	margin-top: 1rem !important;
}

.container-register footer {
	position: fixed;
	right: 0;
	bottom: 0;
	padding: 1rem;
	font-weight: 500;
	color: #777172;
}

.container-register select {
	background-color: #f2f3f7 !important;
}

.container-register .row-login a.link {
	text-align: center;
	color: black;
	font-weight: 500;
}

@media only screen and (max-width: 1366px) {
	.container-register .container-form {
		width: 80vw !important;
	}
}

@media only screen and (max-width: 672px) {
	.container-register .col-title {
		font-size: 60%;
	}

	.container-register label {
		text-align: left;
	}

	#btn-register {
		width: 80% !important;
	}

	.container-register .container-form {
		padding: 1rem !important;
		max-height: 70%;
		overflow: scroll;
	}
}
