.user-management {
	height: 100%;
	padding: 2rem;
	overflow: hidden;
}

.user-management .heading {
	font-size: 200%;
	font-weight: bold;
	padding-left: 1rem;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	max-height: 6%;
}

.user-management .icon-excel {
	margin-left: 1rem;
}

.container-table-user-management {
	margin-top: 1.5rem;
	height: 94%;
	overflow-y: auto;
}

.table-user-management thead {
	text-align: center;
	border-bottom: 2px solid #bfbcbc;
	position: sticky;
	top: 0;
	background: white;
}

.table-user-management th {
	width: fit-content;
	white-space: nowrap;
}

.table-user-management td {
	text-align: center;
	border: 0;
}

.table-user-management .td-view-activity-log:hover {
	cursor: pointer;
	opacity: 0.7;
}

.table-user-management tr:hover {
	cursor: pointer;
	background: #f2f3f7;
}

.table-user-management .pending {
	color: red;
}

.sort {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==)
		no-repeat center right !important;
}
.sort_asc {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIRnC2nKLnT4or00Puy3rx7VQAAOw==)
		no-repeat center right !important;
}
.sort_desc {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=)
		no-repeat center right !important;
}

.row-search {
	margin-bottom: 1.5rem;
}

.user-management #searchText {
	width: auto;
	margin-left: auto;
	margin-right: 0;
	border: 2px solid #898989;
}

.user-management input:focus {
	box-shadow: none !important;
	border: 2px solid black !important;
}

.user-management .span-search-icon {
	width: fit-content;
	margin-top: auto;
	margin-bottom: auto;
}

@media only screen and (max-width: 672px) {
	.user-management .heading {
		font-size: 150%;
	}

	.fg-search {
		justify-content: center;
	}

	.user-management #searchText {
		width: 80%;
	}
}
