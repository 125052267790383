.activity-log {
	padding: 2rem !important;
	height: 100%;
	overflow: hidden;
}

.activity-log .row-heading {
	max-height: 6%;
}

.activity-log .col-heading {
	width: fit-content;
	font-size: 200%;
	font-weight: bold;
	padding-left: 1rem;
	text-transform: uppercase;
}

.col-excel-icon {
	display: flex;
	align-items: center;
	margin-left: 0;
}

.activity-log .row-fg-period {
	display: flex;
	align-items: center;
}

.activity-log .col-form-label {
	width: fit-content;
	font-weight: 600;
	margin-right: 1rem;
}

.activity-log .col-input {
	margin-right: 1rem;
}

.activity-log .btn-apply {
	background-color: white;
	color: black;
	width: fit-content;
	font-weight: 600;
	border: 0;
	border-radius: 0.5rem;
	padding: 0.5rem 1rem 0.5rem 1rem;
}

.activity-log .btn-apply:hover {
	background-color: black;
	color: white;
	width: fit-content;
	border: 0;
	transition-duration: 0.2s;
}

.container-table-activity-log {
	margin-top: 1.5rem;
	overflow-y: scroll;
	height: 94%;
}

.container-table-activity-log #searchText {
	width: 250px;
	margin-left: auto;
	margin-right: 0;
	border: 2px solid #898989;
}

.container-table-activity-log input:focus {
	box-shadow: none !important;
	border: 2px solid black !important;
}

.container-table-activity-log .search-icon {
	margin-left: 1rem;
}

.container-table-activity-log .col-input {
	width: fit-content;
}

.table-activity-log {
	margin-top: 1rem;
}

.table-activity-log thead {
	text-align: center;
	border-bottom: 2px solid #bfbcbc;
	position: sticky;
	top: 0;
	background: white;
}

.table-activity-log td {
	text-align: center;
}

.sort {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==)
		no-repeat center right !important;
}
.sort_asc {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIRnC2nKLnT4or00Puy3rx7VQAAOw==)
		no-repeat center right !important;
}
.sort_desc {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=)
		no-repeat center right !important;
}

@media only screen and (max-width: 672px) {
	.activity-log .row-fg-period {
		flex-wrap: wrap;
	}

	.activity-log .col-input {
		width: 100%;
	}

	.activity-log .btn-apply {
		margin: 1rem auto 0 auto;
	}

	.container-table-activity-log #searchText {
		margin-top: 1rem;
		width: 80%;
	}

	.container-table-activity-log .search-icon {
		margin-top: 1rem;
	}
}
