.container-forgot-password {
	padding: 1rem;
}

.container-forgot-password .row-heading {
	padding-left: 1rem;
	font-size: 200%;
	font-weight: 600;
	text-transform: uppercase;
	color: #6b6666;
}

.container-forgot-password .row-form {
	text-align: center;
}

.container-forgot-password .form-group {
	justify-content: center;
	margin-top: 1rem !important;
}

.container-forgot-password label {
	text-align: right;
	font-weight: 500;
	color: #6e6969;
}

.container-forgot-password input {
	border-radius: 0.5rem !important;
}

.container-forgot-password form {
	height: 80% !important;
	margin: auto !important;
}

#btn-forgot-password {
	background-color: #000000 !important;
	font-size: 150% !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	border-radius: 0.4rem !important;
	width: 40%;
}

#btn-forgot-password:hover {
	background-color: white !important;
	color: black !important;
	font-size: 150% !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	border-radius: 0.4rem !important;
	width: 40%;
	transition-duration: 0.3s;
}

.container-forgot-password .row-login a.link {
	margin-top: 1rem;
	text-align: center;
	color: black;
	font-weight: 500;
}

.container-forgot-password .row-feedback {
	justify-content: center;
	color: red;
	margin-top: 1rem !important;
}

.container-forgot-password footer {
	position: fixed;
	right: 0;
	bottom: 0;
	padding: 1rem;
	font-weight: 500;
	color: #777172;
}

.container-forgot-password .row-or {
	margin-top: 0.5rem !important;
	justify-content: center !important;
	color: #6e6969;
}

.container-forgot-password #fg-email {
	margin-top: 0.5rem !important;
}

@media only screen and (max-width: 1366px) {
	.container-forgot-password .container-form {
		width: 80vw !important;
	}
}

@media only screen and (max-width: 672px) {
	.container-forgot-password .col-title {
		font-size: 60%;
	}

	.container-forgot-password label {
		text-align: left;
	}

	#btn-forgot-password {
		width: 80% !important;
	}
}
