#container-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

#container-dashboard .icon-excel {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 500;
}

#dashboard-filter {
  width: 18%;
  height: 100%;
  padding: 1rem 0 1rem 0;
  margin-left: 1rem;
}

#dashboard-center {
  width: 45%;
  height: 100%;
  display: flex;
  padding: 1rem 0.5rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
}

#dashboard-right {
  width: 37%;
  height: 100%;
  display: flex;
  padding: 1rem 1rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
}

#filter-container {
  background-color: #f4f5f9;
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  height: fit-content;
}

#filter-container .row-form {
  padding: 0.25rem 0 0.25rem 0;
}

#filter-container .row-form .form-control {
  font-size: 0.95rem;
}

#filter-container .apply {
  padding-top: 0;
  border-bottom: 3px solid #aaa;
  border-radius: 0;
  margin-bottom: 1rem;
}

#filter-container .building-list-pane .heading-1 {
  background: white;
}

#filter-container .row-building {
  margin-top: 0.5rem !important;
}

#filter-container .row-building:hover {
  cursor: default;
  background: none;
}

#filter-container .form-check-input {
  border: 1px solid #000;
}

#filter-container .form-check-input:hover {
  cursor: pointer;
  opacity: 0.8;
}

.footer-note {
  position: absolute;
  bottom: 0;
  margin-bottom: 1rem;
  width: 15%;
}

#dashboard-center .row-date {
  width: 100%;
  font-size: 125%;
  font-weight: bold;
  max-height: 5%;
}

#dashboard-center .container-pie-charts {
  height: 25%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 0.5rem;
  position: relative;
  margin-bottom: 1rem;
}

#dashboard-center .row-pie-charts-title {
  height: 20%;
  padding: 0 1rem 0.5rem 0.5rem;
}

#dashboard-center .pie-charts-title-1 {
  font-size: 130%;
  font-weight: bold;
}

#dashboard-center .pie-charts-title-2 {
  font-size: 200%;
  font-weight: bold;
  padding: 0 1rem 0 1rem;
}

#dashboard-center .row-pie-charts {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#dashboard-center .row-pie-charts .legend {
  height: fit-content;
  width: fit-content !important;
  max-width: 25%;
  font-weight: 500;
  font-size: 100%;
}

#dashboard-center .row-pie-charts .legend-row {
  display: flex;
  align-items: center;
  padding: 0.25rem 0 0.25rem 0;
}

#dashboard-center .row-pie-charts .dot-orange,
#dashboard-center .row-pie-charts .dot-grey,
#dashboard-center .row-pie-charts .dot-red,
#dashboard-center .row-pie-charts .dot-blue {
  height: 30px;
  width: 30px !important;
  margin-right: 0.5rem;
}

#dashboard-center .row-pie-charts .pie-chart {
  height: 100%;
  width: 25% !important;
}

#dashboard-center .container-kw-charts {
  height: 70%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 0.5rem;
}

#dashboard-center .container-kw-charts .row-chart {
  width: 100%;
  height: 50%;
  padding: 1rem;
  position: relative;
}

#dashboard-right .container-bill-1 {
  height: 40%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 0.5rem;
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem 1rem 0.5rem 1rem;
}

#dashboard-right .container-bill-2 {
  height: 25%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
}

#dashboard-right .container-bill-2 .row {
  height: 100%;
}

#dashboard-right .container-bill-2 legend {
  font-size: 130%;
}

#dashboard-right #form-compare legend {
  font-size: 110% !important;
}

#dashboard-right .container-bill-3 {
  height: 35%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 0.5rem;
  padding: 0 0.5rem 1rem 0.5rem;
  position: relative;
}

.loading-txt {
  margin-left: 0.5rem;
  color: red;
}

@media only screen and (max-width: 1366px) {
  #container-dashboard {
    flex-direction: column;
  }

  #dashboard-filter {
    width: 60% !important;
    margin: auto;
  }

  #dashboard-filter .row-form {
    display: flex;
    justify-content: center;
  }

  #dashboard-filter .col-datepicker {
    width: fit-content;
  }

  #dashboard-filter .building-list-pane .row-building {
    width: fit-content;
    margin-left: 25% !important;
  }

  #dashboard-filter .building-list-pane .row-building div[class^="col-"] {
    width: fit-content;
  }

  #dashboard-filter .building-list-pane .row-building .col-square-building {
    width: fit-content;
    margin: 0 1rem 0 1rem;
    display: flex;
    align-items: center;
  }

  .footer-note {
    display: none;
  }

  #dashboard-center {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    padding: 0;
  }

  #dashboard-right {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    padding: 0;
    padding-bottom: 1rem;
  }
}
