.container-user-info {
	padding: 2rem !important;
}

.container-edit-profile .heading {
	font-size: 200%;
	font-weight: bold;
	padding-left: 1rem;
	text-transform: uppercase;
}

.container-edit-profile .row-content {
	margin-top: 2rem !important;
	justify-content: center !important;
	align-items: center;
}

svg {
	width: fit-content !important;
	padding: 0 !important;
	padding-right: 0.2rem !important;
}

.choose-image {
	align-items: center !important;
	font-weight: 500;
	padding: 0.5rem;
	border-radius: 0.2rem;
}

.choose-image svg {
	vertical-align: baseline;
	margin-right: 0.2rem;
}

.choose-image:hover {
	cursor: pointer;
}

.row-user-image {
	margin-top: 1rem !important;
	justify-content: center;
}

.user-image {
	border-radius: 50%;
}

.row-imagepicker {
	margin-top: 0.8rem !important;
	text-align: center;
}

.green-dot {
	display: inline-block;
	width: 16px !important;
	height: 16px !important;
	background-color: lightgreen;
	border-radius: 50%;
	margin-right: 0.2rem;
	vertical-align: baseline;
}

.red-dot {
	display: inline-block;
	width: 16px !important;
	height: 16px !important;
	background-color: red;
	border-radius: 50%;
	margin-right: 0.2rem;
	vertical-align: baseline;
}

.btn-change-password {
	background-color: black !important;
	color: white !important;
	font-weight: 600 !important;
	border: 2px solid black !important;
}

.btn-change-password:hover {
	background-color: white !important;
	color: black !important;
	transition-duration: 0.3s;
}

.btn-deactivate {
	background-color: transparent !important;
	color: red !important;
	border: 2px solid red !important;
	font-weight: 600 !important;
}

.btn-activate {
	background-color: transparent !important;
	color: green !important;
	border: 2px solid green !important;
	font-weight: 600 !important;
}

.btn-deactivate:hover {
	background-color: red !important;
	color: white !important;
	transition-duration: 0.3s;
}

.btn-activate:hover {
	background-color: green !important;
	color: white !important;
	transition-duration: 0.3s;
}

.td-edit:hover {
	cursor: pointer;
	opacity: 0.7;
}

button.close {
	border: none;
	background-color: white;
	font-size: 120%;
}

.modal-content {
	border-radius: 1rem !important;
}

.container-edit-profile .td-button {
	border: none !important;
}

.row-upload {
	margin-top: 0.2rem !important;
	justify-content: center;
}

.btn-upload {
	width: fit-content !important;
	background-color: transparent !important;
	color: black !important;
	border: none !important;
	font-weight: 600 !important;
}

.btn-upload:hover {
	cursor: pointer !important;
	background-color: black !important;
	color: white !important;
	transition-duration: 0.3s !important;
}

.col-image {
	margin-right: 1rem;
}

.table-user-info {
	vertical-align: middle;
	margin: auto;
}

.table-user-info .pending {
	color: red;
}

.container-edit-profile .btn-approve {
	background-color: transparent !important;
	color: green !important;
	font-weight: 600;
	border: 0;
	padding: 0.5rem 1rem 0.5rem 1rem;
}

.container-edit-profile .btn-approve:hover {
	background-color: green !important;
	color: white !important;
	transition-duration: 0.3s !important;
}

.td-status {
	display: flex;
	align-items: center;
}
