.container-login {
	padding: 1rem;
}

.row-title {
	display: flex;
	align-items: center;
}

.col-brand {
	width: fit-content !important;
	margin-right: 1rem;
}

.col-title {
	text-transform: uppercase;
	font-weight: 600;
	color: #282541;
}

.container-form {
	background-color: #ffffff;
	border-radius: 1rem;
	padding: 3rem !important;
	width: 50vw !important;

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.container-login .row-heading {
	padding-left: 1rem;
	font-size: 200%;
	font-weight: 600;
	text-transform: uppercase;
	color: #6b6666;
}

.container-login .row-form {
	text-align: center;
}

.container-login .form-group {
	justify-content: center;
	margin-top: 1rem !important;
}

.container-login label {
	text-align: right;
	font-weight: 500;
	color: #6e6969;
}

.container-login input {
	border-radius: 0.5rem !important;
}

.container-login form {
	height: 80% !important;
	margin: auto !important;
}

.container-login #btn-login {
	background-color: black !important;
	font-size: 150% !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	border-radius: 0.4rem !important;
	width: 40%;
}

.container-login #btn-login:hover {
	background-color: white !important;
	color: black !important;
	font-size: 150% !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	border-radius: 0.4rem !important;
	width: 40%;
	transition-duration: 0.3s;
}

.container-login .row-forgot-password {
	margin-top: 1rem !important;
	justify-content: center;
}

.container-login .row-link {
	justify-content: center;
	margin-top: 0.5rem !important;
}

.container-login .link {
	color: black;
	font-weight: 500;
	width: fit-content !important;
}

.container-login .row-feedback {
	justify-content: center;
	color: red;
	margin-top: 1rem !important;
}

footer {
	position: fixed;
	right: 0;
	bottom: 0;
	padding: 1rem;
	font-weight: 500;
	color: #777172;
}

@media only screen and (max-width: 1366px) {
	.container-login .container-form {
		width: 80vw !important;
	}
}

@media only screen and (max-width: 672px) {
	.container-login .col-title {
		font-size: 60%;
	}

	.container-login label {
		text-align: left;
	}

	#btn-login {
		width: 80% !important;
	}
}
