#wrapper-bc-system-power {
	width: 100%;
	height: 100%;
}

#bc-building-power {
	height: 100%;
	width: 100%;
}

#bc-building-power:hover {
	cursor: pointer;
}
