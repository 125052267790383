#container-home {
  height: 100%;
  padding: 1rem;
  overflow: hidden !important;
}

#left-top-pane-group {
  height: max-content;
}

.total-energy-consumption-pane {
  border: 1px solid #000000;
  padding: 1rem 1rem 0.25rem 1rem;
  height: max-content;
}

.total-energy-consumption-pane span {
  display: inline;
  width: fit-content;
}

#container-home .legend {
  display: flex;
  flex-direction: column;
}

.dot-grey {
  height: 25px;
  width: 25px !important;
  background-color: #696665;
  border-radius: 50%;
  display: inline-block;
}

.dot-orange {
  height: 25px;
  width: 25px !important;
  background-color: #ff9233;
  border-radius: 50%;
  display: inline-block;
}

.dot-blue {
  height: 25px;
  width: 25px;
  background-color: #3c67be;
  border-radius: 50%;
  display: inline-block;
}

.dot-red {
  height: 25px;
  width: 25px;
  background-color: #fdd865;
  border-radius: 50%;
  display: inline-block;
}

.row-pie-charts {
  height: 50%;
  display: flex;
  align-items: center;
}

.electricity-bill-pane {
  border: 1px solid #000000;
  padding: 1rem;
  margin-top: 0.5rem;
  height: 35%;
}

.electricity-bill-pane span {
  display: inline;
  width: fit-content;
}

.row-progress {
  padding: 1rem;
  padding-top: 0;
}

.progress-bar {
  color: #000;
  overflow: visible;
}

.solar {
  color: #000 !important;
}

#left-bottom-pane-group {
  height: 50%;
  margin-top: 0.5rem;
}

.building-power-consumption-pane {
  border: 1px solid #000000;
  padding: 0.5rem;
  height: 52.5%;
}

.electrical-system-power-consumption-pane {
  border: 1px solid #000000;
  padding: 0.5rem;
  margin-top: 0.5rem;
  height: 52.5%;
}

.right-column {
  position: relative;
}

#container-home .map-campus {
  position: relative;
}

#container-home .map-campus img {
  margin-top: -5rem;
}

#container-home .img-road-1,
#container-home .img-road-2,
#container-home .img-road-campus,
#container-home .img-field,
#container-home .img-pond,
#container-home .img-trees {
  position: absolute;
  width: 100%;
  z-index: -3;
  object-fit: contain;
}

#container-home .img-building {
  position: absolute;
  width: 100% !important;
  margin-top: -5rem;
  z-index: -1;
}

#container-home #img-nida-house {
  z-index: -2;
}

#container-home #img-siam {
  z-index: -1;
}

#chk-bill {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}

#chk-bill:hover {
  cursor: pointer;
}

#label-bill {
  font-weight: bold;
  font-size: 125%;
}

#num-visitors {
  margin-right: 0.5rem;
  font-size: 125%;
  font-weight: bold;
}

.row-top-right {
  margin-bottom: 1rem;
}

.row-building-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.col-building-block {
  padding: 0.2rem;
}

.span-building-block {
  width: fit-content;
}

.bill-building {
  padding: 0.2rem;
  font-size: 150%;
  font-weight: bold;
}

#container-home .label-building {
  font-weight: bold;
  font-size: 130%;
  border-radius: 1rem;
  color: black;
  padding: 0 1rem 0 1rem;
  margin: 0 1rem 0 1rem;
}

#container-home .label-building:hover {
  cursor: pointer;
  opacity: 0.7;
}

.kwh-building {
  font-weight: 600;
  color: #7d7c8a;
}

.percentKwh-building {
  font-size: 200%;
  font-weight: 600;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 1rem 0 1rem;
}

.legend {
  height: 20px;
  width: 80% !important;
  margin: auto;
}

.gradient {
  background-image: linear-gradient(to right, #d1dbde, #d10909);
}

.legend-dot-1 {
  height: 20px;
  width: 20px;
  background-color: #d1dbde;
  border-radius: 50%;
}

.legend-dot-2 {
  height: 20px;
  width: 20px;
}

.legend-dot-3 {
  height: 20px;
  width: 20px;
  background-color: #d10909;
  border-radius: 50%;
}

.dot-container {
  width: fit-content !important;
  padding-top: 1rem;
  padding-bottom: 0.2rem;
}

.legend-percentage {
  width: fit-content !important;
}

#col-visitors {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}

#container-home .row-legend-pc {
  height: 15%;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  padding-bottom: 0.25rem;
}

.row-pc-remarks {
  font-size: 80%;
  padding-top: 0.25rem;
  display: flex;
  justify-content: end;
}

@media only screen and (max-width: 1366px) {
  #container-home {
    overflow: auto !important;
  }

  #container-home .left-column {
    width: 100% !important;
    height: fit-content !important;
  }

  #container-home .right-column {
    width: 100% !important;
    margin-top: 1rem;
  }

  #container-home .row-legend-pc {
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  #container-home .row-pie-charts {
    width: 70%;
    margin: auto;
    margin-bottom: 0.5rem;
  }

  #container-home .building-power-consumption-pane {
    height: 350px;
  }

  #container-home .electrical-system-power-consumption-pane {
    height: 350px;
  }

  #container-home .img-building,
  #container-home .map-campus img {
    margin-top: -6rem;
  }

  #container-home .footer {
    display: none;
  }
}

@media only screen and (max-width: 672px) {
  #container-home .img-building,
  #container-home .map-campus img {
    margin-top: 0;
  }

  #container-home .row-pie-charts {
    width: 100%;
    margin: auto;
    margin-bottom: 0.5rem;
  }

  #container-home .row-pie-charts .col-sm-6 {
    width: 50%;
  }

  #container-home .row-legend-pc {
    gap: 8px;
    justify-content: flex-start;
  }
}
