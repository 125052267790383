.container-set-target {
	padding: 1rem !important;
	height: 100%;
	overflow: hidden;
}

.container-set-target .row-heading {
	max-height: 7%;
}

.container-set-target .row-content {
	margin-top: 0 !important;
	height: 93%;
}

.container-set-target .heading {
	height: 100%;
	font-size: 200%;
	font-weight: bold;
	padding-left: 1rem;
	text-transform: uppercase;
	margin-bottom: 1rem;
}

.container-set-target .heading-historical-data {
	padding-left: 1rem;
	display: flex;
	align-items: center;
}

.container-set-target .heading-historical-data .icon-excel {
	margin-left: 1rem;
}

.container-set-target .col-left {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0 0 0 0.5rem;
}

.row-config {
	flex-grow: 1;
	border: 1px solid #000000;
	border-radius: 0.5rem;
	padding: 1rem;
	padding-right: 0;
	margin: 0 0.5rem 0 0.25rem !important;
}

.row-config label {
	font-weight: 500;
}

.row-config select {
	text-align-last: center;
}

.fg-config-1 {
	margin-top: 0 !important;
}

.form-target {
	height: fit-content !important;
}

.col-btn-set-target {
	text-align: right;
}

.btn-set-target {
	background-color: black !important;
	font-weight: bold !important ;
	padding-left: 2rem !important;
	padding-right: 2rem !important;
	border-radius: 0.5rem !important;
}

.btn-set-target:hover {
	cursor: pointer !important;
	background-color: transparent !important;
	color: black !important;
	border-radius: 0.5rem !important;
	transition-duration: 0.3s !important;
}

.col-note {
	margin: auto !important;
	margin-bottom: 0 !important;
	margin-left: 0 !important;
}

.row-building-users {
	margin-top: 1rem !important;
	border: 1px solid #000000;
	border-radius: 0.5rem;
	padding: 0.5rem;
	padding-left: 0;
	padding-right: 0;
}

.list-pane {
	flex-grow: 1;
	border: 1px solid #000;
	border-radius: 0.5rem;
	margin: 1rem 0.5rem 0 0.25rem;
	overflow: scroll;
}

.list-pane .form {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	font-weight: 600;
}

.list-pane .form label {
	width: 15%;
	text-align: center;
	font-size: 115%;
}

.list-pane .form select {
	width: 30%;
	font-size: 115%;
	text-align-last: center;
}

.list-pane .table-building-users {
	width: 80%;
	padding: 0 1rem 0 1rem;
	margin: 1rem auto 1rem auto;
}

.list-pane .table-building-users > thead {
	font-weight: 500;
	font-size: 120%;
	text-align: center;
	border-bottom: 2px solid #000;
}

.list-pane tr.tr-building > td {
	padding-top: 0.25rem;
}

.list-pane .table-building-users .td-building {
	display: flex;
	align-items: center;
}

.list-pane .building-label {
	margin-left: 1rem;
	font-weight: 600;
	font-size: 120%;
}

.list-pane .table-building-users .td-users {
	text-align: center;
	font-size: 120%;
}

.list-pane .table-building-users .td-tariff {
	text-align: center;
	font-size: 120%;
}

.list-pane .square-building {
	height: 20px;
	width: 20px;
}

.container-historical-data {
	height: 100%;
	border: 1px solid #000;
	border-radius: 0.5rem;
	padding: 1rem;
	overflow-y: scroll;
}

.row-chart-title {
	font-size: 150%;
	font-weight: 600;
	margin-left: 0.5rem !important;
}

.container-set-target .row-chart {
	height: 40%;
	margin-bottom: 1rem;
}

.col-chart {
	padding-right: 0;
	height: 100%;
}

.row-historical-data-title {
	margin-left: 0.5rem !important;
}

.title {
	width: fit-content !important;
	font-size: 175%;
	font-weight: bold;
}

.btn-period {
	width: fit-content !important;
	height: fit-content !important;
	background-color: white !important;
	color: black !important;
	font-weight: 600 !important;
	margin-left: 1rem;
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.btn-period:hover {
	background-color: black !important;
	color: white !important;
}

.btn-period:focus {
	background-color: black !important;
	color: white !important;
	outline: 0 !important;
	box-shadow: none !important;
}

.container-set-target .col-right {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0 1rem 0 0.5rem;
}

.col-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.dot-actual {
	width: 10px !important;
	background-color: #ffb800;
	border-radius: 50%;
}

.dot-actual-light {
	width: 10px !important;
	background-color: #f3efa9;
	border-radius: 50%;
}

.label-actual {
	width: fit-content !important;
	font-weight: 500;
	color: #6d6969;
}

.form-historical-data-charts .form-group {
	margin-top: 0 !important;
}

.form-historical-data-charts .form-check-label {
	display: flex;
	text-align: left;
	margin-top: 0.5rem;
	padding-left: 0;
	padding-right: 0;
	align-items: center;
}

.form-historical-data-charts .form-check-input {
	margin-right: 0.5rem;
	width: 24px;
	height: 24px;
	border-radius: 50% !important;
}

.container-compare-chart {
	height: 25vh !important;
	margin-right: 0 !important;
}

.col-legend {
	margin: auto !important;
}

.block-saved {
	text-align: center;
	padding: 0.5rem 1rem 0.5rem 1rem;
	background-color: #b6cee9;
	border-radius: 0.5rem;
	font-size: 125%;
	font-weight: 600;
	margin: 1rem 0 1rem 0;
	width: 100%;
}

.block-excess {
	text-align: center;
	padding: 0.5rem 1rem 0.5rem 1rem;
	background-color: #ffafaf;
	border-radius: 0.5rem;
	font-size: 125%;
	font-weight: 600;
	width: 100%;
}

.container-capita-chart {
	height: 25vh !important;
	margin-right: 0 !important;
}

.form-target .form-group {
	margin-top: 1rem;
}

@media only screen and (max-width: 1366px) {
	.container-set-target {
		height: 95%;
		overflow: auto;
	}
}

@media only screen and (max-width: 1024px) {
	.container-set-target .heading-historical-data {
		display: none;
	}

	.container-set-target .col-left {
		width: 80%;
		height: fit-content;
		margin: auto;
		margin-bottom: 1rem;
	}

	.container-set-target .row-config,
	.container-set-target .list-pane {
		flex-grow: 0;
	}

	.container-set-target .col-right {
		width: 80% !important;
		margin: auto;
	}

	.container-set-target .row-chart {
		height: 300px;
	}
}

@media only screen and (max-width: 672px) {
	.container-set-target .row-content {
		height: fit-content;
	}

	.container-set-target .col-left {
		width: 100% !important;
	}

	.col-btn-set-target {
		margin-top: 1rem;
	}

	.container-set-target .col-right {
		display: none;
	}
}
