#container-report {
	display: flex;
	height: 100%;
}

#container-report #report-filter {
	height: 100%;
	width: 18%;
	padding: 1rem 0 1rem 0;
	margin-left: 1rem;
}

#container-report .building-list-pane {
	margin-top: 1rem;
}

#select-report {
	padding: 2rem 0 0 5rem;
	width: 82%;
}

#select-report .heading {
	font-size: 200%;
	font-weight: bold;
	margin-bottom: 1rem;
}

#select-report .row-report {
	font-size: 160%;
	font-weight: 500;
	display: flex;
	margin-top: 1rem;
}

#select-report .form-check-input {
	margin-right: 1rem;
}

#select-report .form-check-input:hover {
	cursor: pointer;
}

#select-report button {
	margin-top: 2rem;
	background-color: #000;
	color: white;
	border: 1px solid white;
	border-radius: 0.5rem;
	padding: 0.5rem 1rem 0.5rem 1rem;
	font-weight: 500;
	font-size: 150%;

	-webkit-transition: color 0.2s;
}

#select-report button:hover {
	background-color: white;
	color: black;
	border: 1px solid #000;
	transition-duration: 0.2s;
}

#charts-invisible {
	visibility: hidden;
	position: absolute;
	z-index: -99999;
}

#charts-invisible #wrapper-pc-building-energy-usage {
	width: 250px;
	height: 250px;
}

#charts-invisible #wrapper-bc-building-bill {
	width: 550px;
	height: 250px;
}

#charts-invisible #wrapper-lc-building-power-temp {
	height: 200px;
}

#charts-invisible #wrapper-lc-building-power-humi {
	height: 200px;
}

#charts-invisible #wrapper-bc-building-energy-usage {
	height: 150px;
}

#charts-invisible #wrapper-bc-building-energy-usage-capita {
	height: 150px;
}

@media only screen and (max-width: 1366px) {
	#report-filter {
		width: 25% !important;
	}

	#select-report {
		padding: 1rem 0 0 0;
		width: 75%;
	}
}

@media only screen and (max-width: 1024px) {
	#report-filter {
		width: 30% !important;
	}

	#select-report {
		padding: 1rem 0 0 0;
		width: 70%;
	}
}

@media only screen and (max-width: 672px) {
	#container-report {
		display: flex;
		flex-direction: column;
	}

	#report-filter {
		width: 90% !important;
		margin: auto;
	}

	#report-filter label {
		text-align: left !important;
	}

	#container-report .building-list-pane .row-building {
		display: flex;
	}

	#container-report .building-list-pane .row-building div[class^="col-sm"] {
		width: fit-content !important;
	}

	#container-report .building-list-pane .row-building .col-square-building {
		width: 15px;
		margin: auto 0 auto 0 !important;
	}

	#select-report {
		margin-top: 1rem;
		padding: 1rem;
		width: 100%;
	}
}
