.container-device-manager {
	height: 100%;
	padding: 2rem;
	overflow: hidden;
}

.row-heading {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
}

.container-device-manager .col-heading {
	width: 22%;
	font-size: 200%;
	font-weight: bold;
	padding-left: 1rem;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}

.container-device-manager .icon-excel {
	margin-left: 1rem;
}

.row-heading .col-right {
	width: 78%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.row-heading .col-right input {
	width: 20%;
	margin-right: 1rem;
	border: 1px solid #000;
}

.row-heading .col-right .col-search-icon {
	display: flex;
	align-items: center;
}

.container-device-manager .div-add-meter {
	display: flex;
	align-items: center;
	font-size: 130%;
	font-weight: bold;
	padding: 0.5rem 1rem 0.5rem 1rem;
	border-radius: 0.5rem;
	margin-right: 1rem;
}

.container-device-manager .div-add-meter:hover {
	cursor: pointer;
	opacity: 0.8;
	background-color: black;
	color: white;
	transition-duration: 0.3s;
}

.container-table-device-manager {
	margin-top: 1.5rem;
	height: 94%;
	overflow-y: scroll;
}

.table-device-manager {
	margin-top: 1rem;
}

.row-table {
	padding-left: 2rem;
	padding-right: 2rem;
	height: 85%;
}

.device-table-head {
	text-align: center;
	position: sticky;
	top: 0;
	background-color: #fff;
}

.table-device-manager td {
	text-align: center;
	vertical-align: middle;
}

.sort {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==)
		no-repeat center right !important;
}
.sort_asc {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIRnC2nKLnT4or00Puy3rx7VQAAOw==)
		no-repeat center right !important;
}
.sort_desc {
	background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=)
		no-repeat center right !important;
}

.table-device-manager thead {
	text-align: center;
	border-bottom: 2px solid #bfbcbc;
}

.container-table-device-manager #searchText {
	width: auto;
	margin-left: auto;
	margin-right: 0;
	border: 2px solid #898989;
}

.container-table-device-manager input:focus {
	box-shadow: none !important;
	border: 2px solid black !important;
}

.form-add-meter .form-group {
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
}

.form-add-meter label {
	font-weight: 500;
	font-size: 90%;
}

.btn-add {
	background-color: black !important;
	color: white !important;
	font-weight: 600 !important;
}

.btn-add:hover {
	background: white !important;
	color: black !important;
	transition-duration: 0.3s;
}

.btn-discard {
	background-color: white !important;
	color: black !important;
	font-weight: 600 !important;
}

.btn-discard:hover {
	background-color: black !important;
	color: white !important;
	font-weight: 600 !important;
}

.table-device-manager .icon:hover {
	cursor: pointer;
	opacity: 0.8;
}

.table-device-manager .green-dot {
	width: 16px !important;
	height: 16px !important;
	background-color: lightgreen;
	border-radius: 50%;
	margin: auto;
	vertical-align: middle;
}

.table-device-manager .red-dot {
	width: 16px !important;
	height: 16px !important;
	background-color: lightsalmon;
	border-radius: 50%;
	margin: auto;
	vertical-align: middle;
}

@media only screen and (max-width: 1366px) {
	.container-device-manager .col-heading {
		width: 40%;
	}

	.container-device-manager .container-table-device-manager {
		height: 90%;
	}
}

@media only screen and (max-width: 1366px) {
	.container-device-manager .row-heading {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
		max-height: fit-content !important;
	}

	.container-device-manager .col-heading {
		width: 100%;
	}

	.container-device-manager .col-right {
		display: flex;
		margin: auto;
		width: 100% !important;
	}

	.container-device-manager .div-add-meter {
		margin-right: 0;
		font-size: 125%;
	}

	#searchText {
		width: 60%;
	}

	.div-add-meter {
		width: 100%;
	}

	.container-device-manager .container-table-device-manager {
		height: 90%;
	}
}
