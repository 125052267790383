nav {
	height: 100%;
}

.navbar {
	padding: 1rem;
	background-color: #f2f3f7;
}

.navbar-logo {
	height: 60px;
}

.nav-link,
.nav-item {
	width: 95px;
}

.nav-item {
	text-align: center;
}

.activeLink {
	font-weight: bold;
}

ul {
	display: flex;
	align-items: center;
}

a.dropdown-toggle.nav-link {
	width: 100%;
	padding: 0;
	text-align: center;
}

nav button :active {
	color: white !important;
}

nav .user-image {
	height: 40px;
}

.navbar-logo-2 {
	height: 40px;
}

nav .dropdown-menu.show {
	width: 250px;
}

.nav-item-share {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

nav .share {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

nav .share:hover {
	cursor: pointer;
	font-weight: bold;
}

nav .share svg {
	margin-left: 0.25rem;
}

.navbar-right {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1366px) {
	.navbar-right {
		display: none;
	}

	.wrapper-navbar {
		height: fit-content !important;
	}
}
