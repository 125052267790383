#container-meter {
	height: 100%;
	padding: 1rem;
}

.row-btn-mode {
	height: 10%;
	white-space: nowrap;
	padding: 1rem 2rem 1rem 2rem;
}

#container-meter .btn-map {
	width: 50%;
	border-radius: 0.5rem 0 0 0.5rem;
	border-right-width: 0.5px !important;
	border: 2px solid black;
	background-color: white;
	color: black;
	font-weight: bold;
	background-image: none;
	font-size: 125%;
	text-transform: uppercase;
}

#container-meter .btn-diagram {
	width: 50%;
	border-radius: 0 0.5rem 0.5rem 0;
	border-left-width: 0.5px !important;
	border: 2px solid black;
	background-color: white;
	color: black;
	font-weight: bold;
	background-image: none;
	font-size: 125%;
	text-transform: uppercase;
}

#container-meter .btn:hover {
	cursor: pointer !important;
	background-color: black !important;
	color: white !important;
	transition-duration: 0.3s;
}

#container-meter button.active {
	background-color: black;
	color: white;
}

#container-meter button.active:focus {
	box-shadow: none;
}

#container-meter .row-overall {
	margin: auto;
	font-size: 120%;
	font-weight: bold;
	text-transform: uppercase;
	justify-content: center;
}

#container-meter .row-overall:hover {
	cursor: pointer;
	background-color: #b4d7ff;
}

#container-meter .row-input {
	white-space: nowrap;
	margin-bottom: 1rem;
}

#container-meter .row-input #searchText {
	width: 20%;
	margin-left: auto;
}

#container-meter .row-input span {
	width: fit-content;
}

#container-meter .col-table-heading {
	font-weight: bold;
	font-size: 150%;
	text-align: right;
	margin-top: auto;
	margin-right: 1rem;
}

#container-meter .col-excel-icon {
	vertical-align: baseline;
}

#container-meter .table-device-manager {
	margin-top: 0;
}

#container-meter .meter-map {
	height: 90%;
	overflow-y: hidden;
}

#container-meter .map-campus {
	position: relative;
	height: 50%;
}

#container-meter .map-campus .row-building-block {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translate(-50%, +30%);
	z-index: 100;
	width: 100%;
}

#container-meter .map-campus .building-block {
	width: calc(100% / 12.5);
	padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	margin: 0 0.5rem 0 0.5rem;
	font-weight: bold;
	font-size: 110%;
	border-radius: 1rem;
}

#container-meter .map-campus .building-block:hover {
	cursor: pointer;
	opacity: 0.7;
}

#container-meter .img-road-1,
#container-meter .img-road-2,
#container-meter .img-road-campus,
#container-meter .img-field,
#container-meter .img-pond,
#container-meter .img-trees {
	position: absolute;
	height: 100%;
	z-index: -1;
	object-fit: cover;
	left: 50%;
	transform: translate(-50%, +10%);
}

#container-meter .img-building {
	position: absolute;
	height: 100%;
	object-fit: cover;
	left: 50%;
	transform: translate(-50%, +10%);
}

#container-meter .img-building > div,
#container-meter .img-building > div > svg {
	height: 100%;
}

#container-meter #img-nida-house {
	z-index: -1;
}

#container-meter #img-siam {
	z-index: 2;
}

#container-meter .container-table-device-manager {
	margin-top: 0;
	height: 45%;
}

#container-meter .table-device-manager thead {
	position: sticky;
	top: 0;
	background: white;
}

#container-meter .table-device-manager tbody {
	overflow-y: scroll;
	overflow-x: hidden;
}

#container-meter .row-diagram {
	padding: 1rem;
	height: 90%;
}

#container-meter .col-right {
	height: 100%;
}

#container-meter .row-title {
	max-height: 10%;
	font-weight: 500;
	font-size: 225%;
	align-items: baseline;
	padding-left: inherit;
}

#container-meter .row-title .latest-time {
	display: inline-block;
	width: fit-content;
	font-size: 150%;
}

#container-meter .row-title .kw-total {
	display: inline-block;
	width: fit-content;
	font-size: 100%;
	text-transform: none;
	color: #898787;
}

#container-meter .meter-buildings {
	border: 1px solid #000;
	height: 80%;
	border-radius: 0.5rem;
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

#container-meter .meter-building {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 30%;
	height: 25%;
}

#container-meter .meter-building .meter {
	width: 30%;
	height: 100%;
	display: flex;
	align-items: center;
}

#container-meter .meter-building img {
	width: 100%;
	height: auto;
	filter: brightness(2.5);
}

#container-meter .meter-building .info {
	width: 70%;
	flex-wrap: wrap;
}

#container-meter .meter-building .label {
	width: 80%;
	text-align: center;
	padding: 0.5rem;
	margin-left: 1rem;
	font-size: 150%;
	font-weight: 600;
	color: white;
	border-radius: 0.5rem;
}

#container-meter .meter-building .label:hover {
	cursor: pointer;
	opacity: 0.7;
}

#container-meter .meter-building .kw {
	width: 100%;
	text-align: center;
	margin-top: 0.2rem;
	font-size: 150%;
	font-weight: 500;
	color: #898785;
}

#container-meter .row-meter-main {
	width: 100%;
	height: 15%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

#container-meter .meter-main {
	width: 45%;
	height: 100%;
	background-color: #e9e9e9;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	margin: 0 1rem 0 1rem;
}

#container-meter .meter-main:hover {
	cursor: pointer;
	opacity: 0.7;
}

#container-meter .meter-main .meter {
	width: 30%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#container-meter .meter-main img {
	height: 100%;
	filter: brightness(2.5);
	padding: 0.5rem;
}

#container-meter .meter-main .info {
	width: 50%;
	text-align: center;
}

#container-meter .meter-main .label {
	font-size: 150%;
	font-weight: bold;
}

#container-meter .meter-main .kw {
	font-size: 140%;
	font-weight: 600;
}

#container-meter .meter-main .status {
	width: 20%;
	text-align: right;
	padding-right: 2rem;
}

#container-meter .container-table {
	height: 80%;
	width: 100%;
	margin-top: 2rem;
	display: flex;
	justify-content: center;
}

#container-meter .container-table thead {
	height: 10%;
}

#container-meter .container-table th {
	text-transform: uppercase;
	font-size: 150%;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	padding: 1rem 0 1rem 0;
	color: white;
}

#container-meter .container-table table {
	height: 100%;
	width: 50%;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}

#container-meter .table-ac {
	text-align: center;
	margin: 0 1rem 0 1rem;
	background-color: #96b0e8;
}

#container-meter .table-ac thead {
	background-color: #4469b8;
}

#container-meter .tbody {
	height: 100%;
	overflow: auto;
	padding: 1rem;
}

#container-meter .table-others {
	text-align: center;
	margin: 0 1rem 0 1rem;
	background-color: #ecb09c;
}

#container-meter .table-others thead {
	position: sticky;
	top: 0;
	background-color: #b14926;
}

#container-meter .container-table .meter-system {
	width: 90%;
	background-color: white;
	margin: 0.5rem auto 0.5rem auto;
	padding: 1rem;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
}

#container-meter .container-table .meter-system:hover {
	cursor: pointer;
	opacity: 0.7;
}

#container-meter .container-table .meter {
	width: 30%;
}

#container-meter .container-table .meter-system img {
	width: 80%;
	filter: brightness(2.5);
	padding: 0.5rem;
}

#container-meter .container-table .info {
	width: 60%;
}

#container-meter .container-table .label-top {
	font-size: 150%;
	font-weight: bold;
}

#container-meter .container-table .label-bottom {
	font-size: 120%;
	font-weight: 600;
}

#container-meter .container-table .status {
	width: 10%;
}

#container-meter .row-diagram .green-dot,
#container-meter .row-diagram .red-dot {
	width: 30px !important;
	height: 30px !important;
}

.modal {
	overflow-y: hidden !important;
}

.modal-dialog {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	height: 100vh;
}

.modal-meter .modal-content {
	transform: translate(0, -5%);
}

.modal-meter .modal-title {
	width: 100%;
}

.modal-meter .modal-title-content {
	display: flex;
}

.modal-meter .modal-title-content .status {
	display: flex;
	align-items: center;
	margin-right: 1rem;
	width: fit-content;
}

.modal-meter .modal-title-content .brand-model {
	color: #838182;
	font-size: 100%;
	margin-left: 1rem;
}

.modal-meter .body-row-1 {
	max-height: 25%;
	padding: 1rem;
	display: flex;
	align-items: center;
}

.modal-meter .body-row-1 .meter-img {
	height: 100%;
	width: 30%;
	display: flex;
	justify-content: center;
}

.modal-meter .body-row-1 img {
	width: 80%;
	filter: brightness(2.5);
}

.modal-meter .body-row-1 table {
	width: 40%;
	margin: auto;
	font-size: 120%;
}

.modal-meter .body-row-1 td {
	padding: 0.3rem 0 0.3rem 0;
}

.modal-meter .body-row-2 {
	height: 75%;
	width: 100%;
	padding: 1rem;
	margin: auto;
}

.modal-meter .body-row-2 table {
	width: 100%;
	margin: auto;
	font-size: 120%;
}

.modal-meter .body-row-2 .spacer {
	height: 1rem;
}

.modal-meter .body-row-2 td:nth-child(odd) {
	width: 35%;
}

.modal-meter .body-row-2 td:nth-child(even) {
	width: 15%;
}

.modal-meter .modal-footer {
	justify-content: flex-start;
	align-items: center;
}

.modal-meter .footer-row-1 span {
	font-size: 125%;
	font-weight: 600;
	background-color: #f4f5f9;
	padding: 0.5rem 1rem 0.5rem 1rem;
	margin-right: 0.5rem;
	border-radius: 0.5rem;
}

.modal-meter form {
	width: 100%;
	display: flex;
	align-items: center;
}

.modal-meter label {
	margin-right: 1rem;
	margin-left: 1.5rem;
}

.modal-meter .label-interval {
	width: 25%;
}

.modal-meter .col-input-interval {
	width: 75%;
}

.modal-meter #interval {
	text-align-last: center;
}

@media only screen and (max-width: 1366px) {
	#container-meter .row-btn-mode {
		height: 5%;
	}

	#container-meter .building-block {
		width: fit-content !important;
		padding: 0.5rem 1rem 0.5rem 1rem !important;
	}

	#container-meter .row-building-block {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
	}

	#container-meter .container-table-device-manager {
		height: 50%;
	}

	#container-meter .form-group .col-sm-1 {
		width: fit-content !important;
		display: flex;
		align-items: center;
	}

	#container-meter .row-diagram {
		height: 95%;
		padding: 2rem 1rem 2rem 1rem;
	}
}

@media only screen and (max-width: 1024px) {
	.col-left {
		width: 30%;
	}

	.col-right {
		width: 70% !important;
		height: fit-content !important;
	}

	#container-meter .meter-building {
		width: 50%;
	}
}

@media only screen and (max-width: 672px) {
	.row-btn-mode {
		height: fit-content !important;
	}

	.map-campus img[class^="img-"],
	.map-campus div[id^="img-"] {
		display: none;
	}

	#container-meter .map-campus {
		position: static;
	}

	#container-meter .map-campus .row-building-block {
		position: static;
		top: 0;
		left: 0;
		transform: none;
		margin-bottom: 1rem;
	}

	#container-meter .col-table-heading {
		margin-right: 0;
	}

	#container-meter .row-input #searchText {
		width: 25%;
	}

	#container-meter .row-input .span-search-icon {
		margin-top: auto;
		margin-bottom: auto;
	}

	#container-meter .row-diagram .col-left,
	#container-meter .row-diagram .col-right {
		width: 100% !important;
	}

	#container-meter .meter-building {
		width: 100%;
	}

	#container-meter .building-list-pane .row-building {
		width: fit-content;
	}

	#container-meter .building-list-pane .col-square-building {
		width: 15px;
	}

	#container-meter .building-list-pane .col-sm-10 {
		width: fit-content;
	}

	#container-meter .meter {
		display: none !important;
	}

	#container-meter .meter-main {
		width: 100%;
		margin-bottom: 1rem;
		display: flex;
		justify-content: center;
		padding: 0.5rem 1rem 0.5rem 1rem;
	}

	#container-meter .meter-main > div {
		width: fit-content !important;
	}

	#container-meter .meter-main .status {
		text-align: right;
		padding-left: 1rem;
		padding-right: 0;
	}

	#container-meter .container-table {
		flex-direction: column;
		flex-wrap: wrap;
	}

	#container-meter .container-table .table-ac,
	#container-meter .container-table .table-others {
		width: 90%;
		height: 500px;
		margin-bottom: 1rem;
	}

	#container-meter .container-table .meter-system {
		display: flex;
		justify-content: center;
	}

	#container-meter .container-table .meter-system .info {
		width: 80%;
		font-size: 80%;
	}

	#container-meter .container-table .meter-system .status {
		width: 20%;
	}
}
