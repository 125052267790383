#container-building {
	padding: 0 1rem 1rem 1rem;
}

#container-building-top {
	padding: 1rem;
	height: fit-content;
	max-height: 65%;
}

#row-building-top {
	height: 100%;
}

#row-building-top .row-date {
	font-size: 110%;
	font-weight: 500;
}

.building-list-pane {
	border: 1px solid #f2f3f7;
	border-radius: 0.5rem;
	padding-bottom: 1rem;
}

.building-list-pane .heading-1 {
	font-size: 125%;
	font-weight: 600;
	background-color: #f2f3f7;
	width: 100%;
	text-align: center;
	padding: 0.5rem;
	border-radius: 0.5rem 0.5rem 0 0;
}

.square-building {
	height: 15px;
	width: 15px;
}

.row-building {
	margin-top: 1rem !important;
	margin-left: auto !important;
	margin-right: auto !important;
	font-size: 125%;
	font-weight: 600;
	padding-left: 1rem;
	padding-right: 1rem;
}

.row-building:hover {
	cursor: pointer;
	background-color: #b4d7ff;
}

.col-square-building {
	margin: auto !important;
}

.row-title {
	font-size: 250%;
	font-weight: bold;
	text-transform: uppercase;
}

.row-heading p {
	white-space: nowrap;
	padding-left: 0;
	font-weight: 600;
}

#row-eb {
	margin-top: 1rem;
}

.col-label-1 {
	margin-top: 1rem !important;
	font-size: 150%;
	font-weight: 500;
}

.col-data-1 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 300%;
	font-weight: 600;
	padding-right: 2rem !important;
}

.col-unit-1 {
	display: flex;
	align-items: center;
	font-size: 200%;
	font-weight: 500;
}

.col-label-2 {
	display: flex;
	align-items: center;
	margin-top: 1rem !important;
	font-size: 125%;
	font-weight: 500;
}

.col-data-2 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 200%;
	font-weight: 600;
	padding-right: 2rem !important;
}

#row-pie {
	padding: 1rem 0 1rem 0;
}

#row-pie .dot-blue {
	height: 15px;
	width: 15px;
}

#row-pie .dot-red {
	height: 15px;
	width: 15px;
}

#col-pie {
	height: 150px;
}

#icon-user {
	margin-left: 0.5rem;
}

.row-form {
	background-color: #f2f3f7;
	border-radius: 0.5rem;
	padding: 0.5rem;
	height: fit-content;
}

.label-datepicker {
	text-align: right;
	font-weight: bold;
}

.col-datepicker {
	display: flex;
}

.datepicker {
	width: fit-content !important;
	border-radius: 1rem !important;
	height: 80%;
	margin: auto !important;
}

#row-graph-power {
	border: 1px solid #000000;
	border-radius: 0.5rem;
	margin-top: 1rem !important;
	padding: 0.5rem;
	height: 50%;
	justify-content: center;
}

#btn-apply-bld {
	background-color: transparent;
	color: black;
	border: 0;
	font-weight: 600;
}

#btn-apply-bld:hover {
	background-color: black;
	color: #f2f3f7;
}

#row-graph-compare {
	margin-top: 1rem;
	padding: 0.5rem;
	border: 1px solid #000000;
	border-radius: 0.5rem;
	height: 35%;
}

#col-compare {
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid black;
	padding: 1rem;
}

#container-building #col-graph-bill {
	margin-top: auto;
	margin-bottom: auto;
	position: relative;
}

#col-graph-bill .icon-excel {
	position: absolute;
	top: 0;
	right: 0;
}

#form-compare legend {
	font-weight: 600;
}

#form-compare .form-check {
	margin-top: 0.5rem;
}

#form-compare .form-check-input {
	padding: 0.5rem;
}

#form-compare .form-check-input:checked {
	background-color: black;
	border-color: black;
}

#form-compare .form-check-label {
	font-weight: 600;
}

#container-floor-plan {
	border: 1px solid #000000;
	border-radius: 0.5rem;
	padding: 1rem;
	height: 33%;
	overflow: scroll;
}

#floor-plan-title {
	font-size: 150%;
	font-weight: bold;
	padding-left: 1rem;
	max-width: 20%;
}

#floors {
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 85%;
	justify-content: space-evenly;
}

.floor {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 25%;
	padding: 1rem;
}

.img-floor {
	height: 100%;
	width: 50%;
}

.img-floor > span {
	height: 100%;
	display: flex;
	align-items: center;
}

.data-floor {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 50%;
}

.row-floor {
	margin-bottom: 1rem;
	width: 100%;
	text-align: center;
}

.floor-title {
	font-size: 125%;
	font-weight: 600;
	padding: 0.5rem 0.75rem 0.5rem 0.75rem;
	border-radius: 0.5rem;
	background: #d8dfe2;
	text-transform: uppercase;
}

.floor-percent {
	font-size: 120%;
	font-weight: 600;
	color: #a4b6be;
	margin-left: 0.5rem;
}

.floor-kwh-total {
	font-size: 125%;
	font-weight: 600;
	color: #c04e4e;
}

.row-floor-1 {
	width: 100%;
}

.floor-kwh {
	color: #c04e4e;
}

#wrapper-legend {
	float: right;
	max-width: 80%;
	display: flex;
	flex-direction: row;
}

.legend-desc {
	display: flex;
	flex-direction: column;
	text-align: center;
	font-weight: bold;
}

.legend-graphics {
	display: flex;
	flex-direction: column;
	width: 200px;
}

.legend-dots {
	padding: 0 1rem 0 1rem;
}

#container-floor-plan .legend-dot-1,
#container-floor-plan .legend-dot-3 {
	display: inline-block;
}

#container-floor-plan .legend-dot-3 {
	float: right;
}

.col-data-left {
	padding-right: 2rem;
}

@media only screen and (max-width: 1366px) {
	#row-building-top {
		display: flex;
		flex-direction: column;
	}

	#col-building-list {
		width: 100%;
		margin-bottom: 1rem;
	}

	#col-building-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	#col-building-list .building-list-pane {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	#col-building-list .building-list-pane > div {
		width: fit-content;
	}

	#col-building-list .row-building {
		width: fit-content;
		display: flex;
		flex-wrap: nowrap;
	}

	#col-building-data {
		width: 100% !important;
		display: flex;
	}

	#col-building-data .col-data-left {
		width: 90% !important;
		display: flex;
		flex-direction: column;
		margin: auto;
		margin-bottom: 2rem;
	}

	#row-pie .col-sm-4 {
		text-align: right;
	}

	#col-building-data .col-data-right {
		margin: auto;
		width: 90% !important;
	}

	#container-floor-plan {
		width: 90% !important;
	}

	#container-floor-plan .floor {
		width: 40%;
	}
}

@media only screen and (max-width: 672px) {
	#col-building-list .building-list-pane {
		display: flex;
		justify-content: flex-start;
	}

	#col-building-list .building-list-pane > div {
		width: 100%;
	}

	#col-building-data div[class^="col-label"],
	#col-building-data div[class^="col-unit"] {
		font-size: 100%;
	}

	#col-building-data .col-data-left div[class^="col-data"] {
		font-size: 130%;
	}

	#col-building-list .row-building {
		width: 100%;
	}

	#col-building-list .col-square-building {
		width: fit-content;
	}

	#col-building-data
		.col-data-left
		div[id^="row-"]
		div[class^="col-"]:not(#col-pie) {
		width: fit-content;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-end;
	}

	#row-pie .col-sm-4 > div {
		margin: 0 0.5rem 0 0.5rem;
	}

	#col-building-data .col-data-right label {
		text-align: left;
	}

	#btn-apply-bld {
		margin-top: 0.5rem;
	}

	#col-building-data .col-data-left {
		width: 100% !important;
	}

	#col-building-data .col-data-right {
		width: 100% !important;
	}

	#container-floor-plan {
		width: 100% !important;
	}

	#container-floor-plan .floor {
		width: 100%;
	}

	#row-graph-power,
	#row-graph-power > .row {
		padding: 0;
	}

	#row-graph-compare,
	#row-graph-compare > .row {
		padding: 0;
	}

	#col-compare {
		border: 0;
	}

	#wrapper-legend {
		display: none;
	}
}
