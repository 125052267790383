.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#wrapper-root {
	height: 100vh;
}

#wrapper-content {
	height: 90%;
}

.icon-excel {
	fill: #286d44;
}

.icon-excel:hover {
	cursor: pointer;
	fill: black;
	transition-duration: 0.3s;
}

.recharts-tooltip-wrapper {
	z-index: 100;
}

.no-scroll {
	position: fixed;
	overflow: hidden;
	width: 100%;
}

.locale-selector {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1000000;
}

.locale-selector > span {
	margin: 0.5rem 0.25rem 0 0.25rem;
	text-decoration: underline;
}

.locale-selector > span:hover {
	cursor: pointer;
	background-color: black;
	color: white;
}

@media only screen and (max-width: 1366px) {
	#wrapper-content {
		height: 95%;
	}
}
